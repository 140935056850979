






























import Vue from "vue";
import apiClient from "@/services/apiClient";
import userManager from "@/services/userManager";


export default Vue.extend({

    data() {
        return {
            userManager,
            item: {},
            customer: {},
            users: [],
            types: [],
            print: false,
            name: '',
        };
    },
    computed: {
        availableRoles() {
            const items = [];
            for (const k in this.availableRoleMap) {
                items.push({
                    text: this.availableRoleMap[k],
                    value: k,
                });
            }
            return items;
        },
    },
    created() {
        this.initialize();
        this.getAllUser();
    },
    methods: {
        async initialize() {
            await this.getByIdNotification()
        },
        getByIdNotification() {
            apiClient.diagramGetAll().then(res => {
                if (res && Array.isArray(res.items) && res.items.length > 0) {
                    this.item = res.items[0]
                }
            })
        },
        getName(value, data, keyData = "id", keyName = "name") {
            if (value) {
                if (Array.isArray(data)) {
                    const length = data.length
                    for (let i = 0; i < length; i++) {
                        if (data[i][keyData] === value) {
                            return data[i][keyName]
                        }
                    }
                }
                return value
            }
            return ""
        },
        async getAllUser() {
            apiClient.userGetAll().then(res => {
                this.users = res.items.map(item => {
                    item.id = String(item.id)
                    return item
                })
            }).catch(e => {
                console.log(e)
            }).finally(() => {

            })
        },
    },
});
